import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';

export const routes = [
    { id: 'INTEGRATIONS_PROVIDER', plan: '', path: '/integrations/providers', name: 'Providers' },
    { id: 'INTEGRATIONS_POS', plan: '', path: '/integrations/pos', name: 'Pos' },
  ];

const IntegrationTabs = () => {
  const history = useHistory();
  const [value, setValue] = useState(history.location.pathname);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(newValue);
  };

  return (
      <Box sx={{ width: '100%', borderTop: '1px solid #0000001F' }}>
        <Box sx={{ width: '100%' }}>
          <Tabs
            TabIndicatorProps={{
              style: {
                backgroundColor: '#2D7FF9',
              },
            }}
            variant="scrollable"
            scrollButtons="auto"
            selectionFollowsFocus
            value={value}
            onChange={handleChange}
          >
            {routes.map(({ id, path, name }) => (
              <Tab
                sx={{
                  '&.Mui-selected': { color: '#3C4350' },
                }}
                key={id || path}
                value={`${path}`}
                label={name}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
  );
};
export default IntegrationTabs;
