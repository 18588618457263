import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

/*
 * 🔁 useRedirectProviderToIntegrations
 *
 * Automatically redirects users from the legacy `/providers` route
 * to the new `/integrations/providers` route, preserving all query parameters.
 *
 * 📌 When to use:
 * - Use in a global component (e.g., App or Layout) to handle redirects from third-party services.
 * - This is helpful when the redirect URL is hardcoded to `/providers`.
 *
 * 🧠 How it works:
 * - Uses `useLocation` to detect current path and query.
 * - If the pathname includes `/providers`, it replaces it with `/integrations/providers`.
 * - Uses `history.replace` to avoid adding an entry to browser history.
 *
 * ✅ Example:
 * /providers?code=123 → /integrations/providers?code=123
 */

const useRedirectProviderToIntegrations = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const { pathname, search } = location;

    if (pathname === '/providers') {
      history.replace(`/integrations/providers${search}`);
    }
  }, [location, history]);
};

export default useRedirectProviderToIntegrations;
