// ImageUpload.js
import { Fragment, useEffect, useRef, useState } from 'react';
import cs from 'classnames';
import Preloader from 'components/Preloader';
import { unlinkImage, uploadAttachments } from 'redux/actions/file';
import IconButton from '@mui/material/IconButton';
import { TextField } from '@mui/material';
import CropPhoto from '../ImageUpolad/CropPhoto';
import { useHistory } from 'react-router-dom';
import {
  MAX_FILE_SIZE_MB,
  MIN_IMAGE_UPLOAD_HEIGHT,
  MIN_IMAGE_UPLOAD_WIDTH,
} from 'constants/file';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';

const ImageUpload = ({
  name,
  initialValue,
  removable,
  size,
  disabled,
  className = '',
  model,
  setFormValue = () => {},
  setImage = () => {},
  setDisable = () => {},
  setModel = () => {},
  isBackground = false,
  height = '98',
  width = '98',
  keepLogoOriginalSize = false,
}) => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState('');
  const isBusiness = history.location.pathname.includes('/settings/business');
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(initialValue || { url: '' }); // Initialize with { url: '' }
  const inputRef = useRef();
  const [openCropModal, setOpenCropModal] = useState(false);
  const [imageOptions, setImageOptions] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);

  const handleClose = () => {
    setOpenCropModal(false);
    setImageOptions(null);
    setError(false);
    setFileSizeError('');
  };

  const proceedWithUpload = (originalImage) => {
    const formData = new FormData();
    formData.append('image', originalImage);
    uploadAttachments(formData)
      .then(({ image }) => {
        if (image.url) {
          setValue(image);
          if (name === 'avatar') {
            setFormValue('avatar', image, { shouldDirty: true });
          } else {
            setFormValue('image', image, { shouldDirty: true });
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleValidateImageSize = (image) => {
    if (!keepLogoOriginalSize) {
      const reader = new FileReader();
      const img = new Image();
      img.onload = () => {
        const imgWidth = img.width;
        const imgHeight = img.height;
        const isInvalidSize =
          imgWidth < MIN_IMAGE_UPLOAD_WIDTH ||
          imgHeight < MIN_IMAGE_UPLOAD_HEIGHT;
        const fileSizeInBytes = image.size;
        const fileSizeInMB = Number(fileSizeInBytes / (1024 * 1024));
        const isInvalidFileSize = fileSizeInMB > MAX_FILE_SIZE_MB;
        setFileSizeError(
          isInvalidFileSize ? 'Image too large. Max size: 5MB.' : ''
        );
        setOpenCropModal(true);
        setError(isInvalidSize);
      };
      reader.onloadend = () => {
        setImageSrc(reader.result);
        img.src = reader.result;
      };
      reader.readAsDataURL(image);
    } else {
      setLoading(true);
      const reader = new FileReader();

      reader.onloadend = () => {
        const img = new Image();
        img.onload = () => {
          // Use the actual natural size of the image
          const canvas = document.createElement('canvas');
          canvas.width = img.naturalWidth;
          canvas.height = img.naturalHeight;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          canvas.toDataURL('image/png');
        };
        img.src = reader.result;
        setImageSrc(reader.result);
      };

      reader.readAsDataURL(image);
      proceedWithUpload(image);
    }
  };

  const handleImageCropOpen = (image) => {
    if (!keepLogoOriginalSize) {
      setError(false);
      setOpenCropModal(true);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
        const fileSizeInBytes = image.size;
        const fileSizeInMB = Number(fileSizeInBytes / (1024 * 1024));
        const isInvalidFileSize = fileSizeInMB > MAX_FILE_SIZE_MB;
        setFileSizeError(
          isInvalidFileSize ? 'Image too large. Max size: 5MB.' : ''
        );
      };
      reader.readAsDataURL(image);
    } else {
      setLoading(true);
      const reader = new FileReader();

      reader.onloadend = () => {
        const img = new Image();
        img.onload = () => {
          // Use the actual natural size of the image
          const canvas = document.createElement('canvas');
          canvas.width = img.naturalWidth;
          canvas.height = img.naturalHeight;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          canvas.toDataURL('image/png');
        };
        img.src = reader.result;
        setImageSrc(reader.result);
      };

      reader.readAsDataURL(image);
      proceedWithUpload(image);
    }
  };

  const handleChange = ({ target: { files } }) => {
    const image = files[0];
    if (image) {
      const imageOptions = {
        name: image.name,
        type: image.type,
        path: URL.createObjectURL(image),
      };
      setImageOptions(imageOptions);
      isBusiness ? handleImageCropOpen(image) : handleValidateImageSize(image);
    }
    if (inputRef.current) {
      inputRef.current.value = ''; // Reset the input value to allow re-uploading the same file
    }
  };

  const handleDelete = () => {
    unlinkImage(value.fileId || value.imageId).then(() => {
      setImage('');
      setValue({ url: '' }); // Set to { url: '' } instead of ''
      if (name === 'avatar') {
        setFormValue('avatar', { fileId: '', url: '' }, { shouldDirty: true });
      } else {
        setFormValue('image', { fileId: '', url: '' }, { shouldDirty: true });
      }
      setDisable(false);
    });
  };

  useEffect(() => {
    setValue(initialValue || { url: '' }); // Ensure value is always an object with url
  }, [initialValue]);

  const handleEditImage = () => {
    if (value?.url) {
      setError(false);
      setOpenCropModal(true);
      const originalImageUrl = value.url.split('?')[0];
      setImageSrc(originalImageUrl);
      setImageOptions({
        name: originalImageUrl.split('/').pop(),
        type: 'image/jpeg',
        path: originalImageUrl,
      });
    }
  };

  return (
    <div
      className={cs('image-upload', className, {
        disabled,
        empty: !value?.url,
      })}
      style={
        size
          ? {
              width: `${!isBackground ? size : 400}px`,
              height: `${!isBackground ? size : 225}px`,
              overflow: 'hidden',
            }
          : {}
      }
    >
      <TextField value={value?.url || ''} hidden type="text" name={name} />
      <label>
        {!!value?.url && (
          <img
            src={`${value.url}?tr=w-${width},h-${height}${
              value.cropParams
                ? `,cr-${value.cropParams.x}_${value.cropParams.y}_${value.cropParams.width}_${value.cropParams.height}` +
                  `,rt-${value.cropParams.rotation}`
                : ''
            }`}
            style={{
              transform: `scale(${value?.cropParams?.zoom || 1})`,
            }}
            alt=""
          />
        )}
        {!value?.url && (
          <input
            type="file"
            onChange={handleChange}
            disabled={disabled}
            accept="image/png, image/jpeg"
            ref={inputRef}
          />
        )}
        <span className={cs({ 'image-loading': loading, empty: !value?.url })}>
          {loading ? <Preloader /> : <i className="icon icon-camera"></i>}
        </span>
      </label>
      {!disabled && removable && !!value?.url && (
        <Fragment>
          <IconButton
            color="error"
            variant="contained"
            size="small" // Changed from 'sm' to 'small' for MUI compatibility
            onClick={handleDelete}
            className="delete-image p-1"
          >
            <i className="icon icon-trash-2"></i>
          </IconButton>
          <IconButton
            sx={{
              background: '#eee',
              borderRadius: '5px',
            }}
            color="default"
            variant="contained"
            size="small"
            onClick={handleEditImage} // Open crop modal for editing
            className="edit-image"
          >
            <EditIcon fontSize="16" color="secondary" />
          </IconButton>
        </Fragment>
      )}
      {openCropModal && (
        <CropPhoto
          disabled={disabled}
          handleChange={handleChange}
          inputRef={inputRef}
          photoURL={imageSrc}
          setImageSrc={setImageSrc}
          setFormValue={setFormValue}
          setPhotoURL={() => {}}
          openCropModal={openCropModal}
          title={
            name === 'avatar'
              ? 'settings.business_logo'
              : name === 'image' && !isBusiness
              ? 'menu.product_photo'
              : 'settings.cover_photo'
          }
          className={'logo-content'}
          imageOptions={imageOptions}
          handleClose={handleClose}
          setValue={setValue}
          name={name}
          error={error}
          setError={setError}
          fileSizeError={fileSizeError}
        />
      )}
    </div>
  );
};

// Define PropTypes for better type checking and to prevent undefined props
ImageUpload.propTypes = {
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.shape({
    url: PropTypes.string,
    fileId: PropTypes.string,
    imageId: PropTypes.string,
  }),
  removable: PropTypes.bool,
  size: PropTypes.number,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  model: PropTypes.object,
  setFormValue: PropTypes.func,
  setImage: PropTypes.func,
  setDisable: PropTypes.func,
  setModel: PropTypes.func,
  isBackground: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ImageUpload;
