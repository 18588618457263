import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
import {
  CropActionWrap,
  CropCancelButton,
  CropDialogActionWrapper,
  CropDialogActionWrapperError,
  CropDialogSlider,
  CropDialogZoomRotationActionWrapper,
  CropFooterBox,
  CropFooterDivider,
  CropImageDialogContent,
  CropSaveButton,
} from 'components/ImageUpolad/ImageLogoUpload/styles';
import TurnLeft from 'assets/icons/turnLeft.svg';
import TurnRight from 'assets/icons/turnRight.svg';
import { useBusinessPhotoCrop } from 'components/ImageUpolad/ImageLogoUpload/hooks/useBusinessPhotoCrop';
import AddIcon from '@mui/icons-material/Add';

const titleStyles = {
  fontSize: '24px',
  fontWeight: '400',
  lineHeight: '32px',
  color: '#1D1B20',
};

export const IMode = {
  LEFT: 'left',
  RIGHT: 'right',
};

const CropPhoto = ({
  photoURL,
  setImageSrc,
  openCropModal,
  title,
  className,
  imageOptions,
  handleClose,
  setFormValue,
  isCoverPhoto = false,
  setValue = () => {},
  name,
  error = false,
  setError = () => {},
  fileSizeError = '',
  handleChange = () => {},
  disabled,
  inputRef,
}) => {
  const { t } = useTranslation();
  const {
    handleRotate,
    cropImage,
    cropComplete,
    zoom,
    crop,
    rotation,
    setRotation,
    loading,
    errorMessage,
    onCropChange,
    onZoomChange,
    onZoomChangeManualChange,
  } = useBusinessPhotoCrop(
    photoURL,
    imageOptions,
    setImageSrc,
    setFormValue,
    handleClose,
    isCoverPhoto,
    setValue,
    name,
    error,
    setError
  );

  const CustomDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 24,
              top: 16,
              color: '#929398',
            }}
          >
            <CloseIcon sx={{ fontSize: '24px', color: '#929398' }} />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <Dialog
      open={openCropModal}
      onClose={handleClose}
      PaperProps={{
        sx: { width: '662px', height: '540px', maxWidth: '100%' },
      }}
      PaperComponent={DraggableDialogWrapper}
    >
      <CustomDialogTitle
        id="alert-dialog-title"
        onClose={handleClose}
        sx={titleStyles}
      >
        {t(title)}
      </CustomDialogTitle>
      <CropImageDialogContent dividers className={className}>
        <Cropper
          image={photoURL}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={name === 'avatar' ? 1 : 16 / 9}
          onZoomChange={onZoomChange}
          onRotationChange={setRotation}
          onCropChange={onCropChange}
          onCropComplete={cropComplete}
        />
      </CropImageDialogContent>
      <DialogActions sx={{ flexDirection: 'column', mx: 3, my: 2 }}>
        <CropDialogActionWrapper>
          <CropDialogZoomRotationActionWrapper>
            <Typography>{t('settings.zoom')}</Typography>
            <CropDialogSlider
              valueLabelDisplay="auto"
              valueLabelFormat={zoomPercent}
              min={100}
              max={300}
              step={1}
              aria-label="Small"
              value={zoom * 100}
              size="small"
              onChange={(e, zoom) => {
                const newZoom = zoom / 100;
                onZoomChangeManualChange(newZoom);
              }}
            />
          </CropDialogZoomRotationActionWrapper>
          <CropDialogZoomRotationActionWrapper>
            <Typography>{t('settings.straighten')}</Typography>
            <CropDialogSlider
              valueLabelDisplay="auto"
              size="small"
              min={0}
              max={360}
              step={1}
              value={rotation}
              onChange={(e, rotation) => setRotation(rotation)}
            />
          </CropDialogZoomRotationActionWrapper>
          <CropDialogZoomRotationActionWrapper>
            <CropActionWrap>
              <img
                style={{
                  opacity: rotation < 90 ? '0.5' : '1',
                  cursor: rotation < 90 ? 'not-allowed' : 'pointer',
                }}
                src={TurnLeft}
                alt="turn left"
                onClick={() => handleRotate(90, IMode.LEFT)}
              />
              <img
                style={{
                  opacity: rotation >= 360 ? '0.5' : '1',
                  cursor: rotation >= 360 ? 'not-allowed' : 'pointer',
                }}
                src={TurnRight}
                alt="turn right"
                onClick={() => handleRotate(90, IMode.RIGHT)}
              />
            </CropActionWrap>
          </CropDialogZoomRotationActionWrapper>
        </CropDialogActionWrapper>
        <CropDialogActionWrapperError>
          {errorMessage && (
            <Typography sx={{ fontSize: '14px', color: 'red' }}>
              {errorMessage}
            </Typography>
          )}
          {fileSizeError && (
            <Typography sx={{ fontSize: '14px', color: 'red' }}>
              {fileSizeError}
            </Typography>
          )}
        </CropDialogActionWrapperError>
        <CropFooterDivider />
        <CropFooterBox>
          <Box>
            <TextField value={photoURL || ''} hidden type="text" name={name} />
            <label style={{ display: 'flex' }}>
              <input
                type="file"
                onChange={handleChange}
                disabled={disabled}
                accept="image/png, image/jpeg"
                ref={inputRef}
                style={{ visibility: 'hidden', width: 0 }}
              />
              <Box
                sx={{
                  borderRadius: 1,
                  color: '#929398',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                disabled={loading}
              >
                <AddIcon
                  sx={{
                    color: '#929398',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    marginLeft: 1,
                    color: '#929398',
                    textTransform: 'uppercase',
                  }}
                >
                  Upload New Photo
                </Typography>
              </Box>
            </label>
          </Box>
          <Box>
            <CropCancelButton variant="outlined" onClick={handleClose}>
              {t('orders.cancel')}
            </CropCancelButton>
            <CropSaveButton
              loading={loading}
              variant="contained"
              onClick={cropImage}
              disabled={Boolean(fileSizeError) || Boolean(errorMessage)}
            >
              {t('settings.save')}
            </CropSaveButton>
          </Box>
        </CropFooterBox>
      </DialogActions>
    </Dialog>
  );
};

export default CropPhoto;

const zoomPercent = (value) => {
  return `${Math.round(value)}%`;
};
