import { useEffect, useState } from 'react';
import usePermission from 'hooks/permission';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Statuses from 'constants/providerStatuses';
import useProviderConfig from 'hooks/useProviderConfig';
import { forceMenuProvider, getProviders, getProvidersStatuses, updateProvider } from 'redux/actions/providers';

export const useProviderList = (stopResumeOrders, onDisconnect, data, id) => {
  const { providers } = useProviderConfig();
  const checkPermission = usePermission();
  const userData = useSelector((state) => state.user);
  const { loading, connectionLoading } = useSelector(({ providers }) => providers);
  const dispatch = useDispatch();
  const history = useHistory();
  const [status, setStatus] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [loadingProviderSync, setLoadingProviderSync] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElWebUrl, setAnchorElWebUrl] = useState(null);

  const handleOpenURl = (url) => window.open(url, '_blank');

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const forceMenuSync = (srv) => {
    const provider = srv === 'food2' ? 'website' : srv;
    setLoadingProviderSync(true);
    dispatch(forceMenuProvider(provider))
      .then(() => {
        // dispatch(getProviders());
      })
      .catch(() => setLoadingProviderSync(false))
      .finally(() => setLoadingProviderSync(false));
  };

  const handleClickEyeIcon = (url) => window.open(url, '_blank');

  const handleClose = () => setAnchorEl(null);

  const handleCloseWebUrl = () => setAnchorElWebUrl(null);

  const open = Boolean(anchorEl);
  const openId = open ? 'simple-popover' : undefined;

  const openWebUrl = Boolean(anchorElWebUrl);
  const openWebId = openWebUrl ? 'simple-popover' : undefined;

  const handleDisconnect = () => onDisconnect(data.srv);

  useEffect(() => {
    const status = !!stopResumeOrders.statuses[data.srv];
    setStatus(status);
  }, [stopResumeOrders.statuses]);

  const handleActivate = (item) => {
    const providerId = providers[item?.srv]?.id;
    const values = {
      state: String(Statuses.PROVIDER_STATE_ACTIVE),
      provider: providers[item?.srv]?.srv,
      status: String(Statuses.PROVIDER_CONNECTED),
      verified: userData?.data?.verified,
    };
    dispatch(updateProvider({ ...values, id: providerId })).then(() => {
      dispatch(getProviders());
      dispatch(getProvidersStatuses());
    });
  };

  const handleSettings = () => {
    history.push({ pathname: `/integrations/${data.srv}/settings`, state: { data, id } });
  };

  return {
    handleActivate,
    handleDisconnect,
    checkPermission,
    handleOpenURl,
    providers,
    handleClick,
    handleClose,
    open,
    openId,
    anchorEl,
    handleSettings,
    loading,
    connectionLoading,
    setSelectedProvider,
    selectedProvider,
    forceMenuSync,
    loadingProviderSync,
    handleClickEyeIcon,
    anchorElWebUrl,
    handleCloseWebUrl,
    openWebId,
    openWebUrl,
  };
};
