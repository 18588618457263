import styled from '@emotion/styled';
import {
  Typography,
  Box,
  Button,
  DialogContent,
  Slider,
  Divider,
  IconButton,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
export const LogoWrapper = styled(Box)({
  width: '126px',
  height: '126px',
  border: '1px dashed #929398',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  borderRadius: '6px',
  background: '#FAFAFA',
});

export const LogoPreviewWrapper = styled(Box)({
  // marginTop: '20px',
  position: 'relative',
  width: '126px',
  top: '-21px',
  left: '-21px',
  '> img': {
    width: '126px',
    height: '126px',
    objectFit: 'cover',
  },
});

export const LogoPreviewDeleteIcon = styled(IconButton)({
  background: 'rgba(146, 147, 152, 0.80)',
  color: 'white',
  padding: '5px',
  position: 'absolute',
  borderRadius: '6px',
  '&:hover': {
    background: 'rgba(146, 147, 152, 0.80)',
    color: 'white',
  },
});

export const LoadingIcon = styled(Box)({
  color: 'white',
  padding: '5px',
  top: '30px',
  right: '30px',
  position: 'absolute',
  borderRadius: '6px',
  bottom: '30px',
  zIndex: '23px',
});

export const LoadingCoverIcon = styled(Box)({
  color: 'white',
  padding: '5px',
  top: '150px',
  right: '270px',
  position: 'absolute',
  borderRadius: '6px',
  bottom: '30px',
  zIndex: '23px',
});

export const LogoTitle = styled(Typography)({
  color: '#929398',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  paddingBottom: '8px',
  margin: 0,
});
export const AccordionContentTitle = styled(Typography)({
  fontSize: '22px',
  fontWeight: 400,
  color: ' #3C4350',
  lineHeight: '28px',
  width: '100%',
  margin: '4px 0',
});

export const AccordionEditIcon = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  marginRight: '8px',
});

export const AccordionContentWrapper = styled(Box)({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
});

export const ButtonFooter = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '16px',
  alignItems: 'center',
});

export const SaveButtonFooter = styled(LoadingButton)({
  width: '71px',
  height: '36px',
  padding: '16px 32px',
  background: '#FF8A00',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 600,
  borderRadius: '6px',
  '&:hover': {
    background: '#FF8A00',
  },
});

export const CancelButtonFooter = styled(Button)({
  width: '85px',
  height: '36px',
  padding: '16px 32px',
  color: '#929398',
  fontSize: '14px',
  fontWeight: 600,
  '&:hover': {
    color: '#929398',
  },
});

export const CropImageDialogContent = styled(DialogContent)({
  background: '#333',
  position: 'relative',
  height: 400,
  width: 'auto',
});

export const CropDialogActionWrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  gap: '24px',
});

export const CropDialogZoomRotationActionWrapper = styled(Box)({
  width: '100%',
  color: '#929398',
  fontSize: '16px',
  alignItems: 'center',
});

export const CropDialogActionWrapperError = styled(Box)({
  width: '100%',
  display: 'flex',
  marginLeft: '-4px!important',
});

export const CropDialogSlider = styled(Slider)({
  color: '#FF8A00',
  ':hover': {
    '& .MuiSlider-thumb': { boxShadow: '0 0 0 8px rgba(255, 138, 0, 0.3)' },
  },
  width: '250px',
});

export const CropFooterDivider = styled(Divider)({
  width: '654px',
  height: '1px',
  paddingTop: '16px',
});

export const CropFooterBox = styled(Box)({
  display: 'flex',
  gap: '16px',
  flexWrap: 'wrap',
  // justifyContent: 'flex-end',
  paddingTop: '20px',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: 0,
});

export const CropCancelButton = styled(Button)({
  border: 'none',
  color: '#929398',
  '&:hover': { border: 'none', color: '#929398' },
});

export const CropSaveButton = styled(LoadingButton)({
  color: 'white',
  background: '#FF8A00',
  width: '71px',
  height: '36px',
  '&:hover': { background: '#FF8A00' },
});

export const CropActionWrap = styled(Box)({
  marginTop: '24px',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '5px',
  cursor: 'pointer',
  '&:img': {
    cursor: 'pointer',
  },
});
