import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { setReportFilter } from 'redux/actions/reports';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  ListItemText,
  Checkbox,
  FormGroup,
  Divider,
  Box,
  FormControlLabel,
} from '@mui/material';
import { allDays } from './configTable';
import FilterDialog from './FilterDialog';
import { handleTypeName } from './utils';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ButtonWrapper } from './styles';
import { useTranslation } from 'react-i18next';
import useProviderConfig from 'hooks/useProviderConfig';
import Tooltip from '@mui/material/Tooltip';
import dateIssue from 'assets/icons/dateIssue.svg';
import { getAllBusinessGroups } from 'redux/actions/business';
import BusinessGroup from 'components/Forms/BusinessGroup';
import { SET_ALL_BUSINESS_GROUPS } from 'redux/actions/types';
import LocationAutoCompleteReport from 'components/Forms/LocationAutoCompleteReport';
import { CustomBrandAutoCompleteReport } from 'components/Forms/CustomBrandAutoComplateReport';
import * as all from 'constants/permissions';
import usePermissionPlan from 'hooks/permissionPlan';
import {
  selectAllBrandIdsForReport,
  selectAllFilterBrandList,
  selectAllLocations,
  selectFilterListReport,
  selectLocationIdsForReport,
  selectAllBusinessGroups,
  selectUserBusinessListReport,
} from 'redux/selectors';

const createBrandWorker = createWorkerFactory(() =>
  import('components/Forms/convertBrand')
);

export const BreakDownEnum = {
  LOCATION: 'location',
  BRAND: 'brand',
  BRAND_AND_LOCATION: 'brandAndLocation',
};

const FilterReport = ({
  handleFilterSubmit,
  model,
  currentFilter,
  setCurrentFilter,
  setModel,
  filteredList,
}) => {
  const dispatch = useDispatch();
  const checkPermissionPlan = usePermissionPlan();
  const dropdownRef = useRef(null);
  const CHARGEBACK_DISPUTE_REPORT = 'CHARGEBACK_DISPUTE_REPORT';
  const { providers: providersConfig } = useProviderConfig();
  const worker = useWorker(createBrandWorker);
  const [convertedBrand, setConvertedBrand] = useState([]);
  const [breakdownBrand, setBreakdownBrand] = useState(false);
  const [breakdownLocation, setBreakdownLocation] = useState(false);

  const businessList = useSelector(selectUserBusinessListReport);
  const filterList = useSelector(selectFilterListReport);
  const filterBrandList = useSelector(selectAllFilterBrandList);
  const allLocations = useSelector(selectAllLocations);
  const locationIdsForReport = useSelector(selectLocationIdsForReport);
  const brandIdsForReport = useSelector(selectAllBrandIdsForReport);
  const allBusinessGroups = useSelector(selectAllBusinessGroups);
  const allProviders = Object.keys(filteredList);

  useEffect(() => {
    setBreakdownLocation(false);
    setBreakdownBrand(false);
    getAllBusinessGroups().then((res) =>
      dispatch({ type: SET_ALL_BUSINESS_GROUPS, payload: res?.data?.data })
    );
  }, []);

  const { t } = useTranslation();

  const [formErrors, handleFormErrors] = useState(false);
  const [startDateError, handleStartDateError] = useState(false);
  const [endDateError, handleEndDateError] = useState(false);

  const { type, start_date, end_date, days_of_week, providers } = model;

  useEffect(() => {
    (async () => {
      const webWorkerBrand = await worker.convertBrand(filterBrandList);
      setConvertedBrand(webWorkerBrand);
    })();
  }, [filterBrandList, worker]);

  const handleSubmit = () => {
    if (type !== 'SALES_BY_PROVIDER') {
      delete model.breakDown;
    }
    if (currentFilter) {
      return handleFilterSubmit({ ...model, type: currentFilter.data.type });
    } else {
      return handleFilterSubmit(model);
    }
  };

  const handleModelField = (event, child) => {
    const { target } = event;
    const { name, value } = target;
    const { data, dataid } = child.props;
    setBreakdownLocation(false);
    setBreakdownBrand(false);
    // All Selected Days.
    if (target?.name === 'days_of_week' && child.props.value === 'checkAll') {
      if (value.length - 1 === allDays.length) {
        return setModel({
          ...model,
          days_of_week: [],
        });
      } else {
        return setModel({
          ...model,
          days_of_week: [...allDays.map((item) => item.value)],
        });
      }
    }

    // All Selected Businesses.
    if (target?.name === 'businesses' && child.props.value === 'checkAll') {
      if (model.businesses.length === businessList.length) {
        return setModel({
          ...model,
          businesses: [],
        });
      }
      return setModel({
        ...model,
        businesses: businessList.map((item) => item._id),
      });
    }
    // All Selected Providers.
    if (target?.name === 'providers' && child.props.value === 'checkAll') {
      if (model.providers.length === allProviders.length) {
        return setModel({
          ...model,
          providers: [],
        });
      }
      return setModel({ ...model, providers: [...allProviders] });
    }
    // Set saved report type
    if (dataid) {
      const current = filterList.find((item) => item.id === dataid);
      setModel(current.data);
    }
    if (name === 'type') {
      if (data) {
        const dataObj = JSON.parse(data);
        setCurrentFilter(dataObj);
        return setModel({
          ...dataObj.data,
          type: value,
        });
      } else {
        setCurrentFilter(null);
        return setModel({ ...model, type: value });
      }
    }
    setModel({
      ...model,
      [name]: value,
    });
    return () => {
      handleModelField(...model);
      dispatch(setReportFilter(model));
    };
  };

  const handleMenuOpen = () => {
    setTimeout(() => {
      if (dropdownRef.current) {
        const scrollContainer =
          dropdownRef.current.querySelector('.MuiList-root');
        if (scrollContainer) {
          scrollContainer.scrollIntoView({
            top: 0,
            behavior: 'auto',
          });
        }
      }
    }, 0);
  };

  useEffect(() => {
    handleFormErrors(
      !model.start_date ||
        !model.end_date ||
        !model.days_of_week.length ||
        !model.providers.length
    );
  }, [model]);

  const handleSaveFilter = (title) => {
    if (currentFilter) {
      return dispatch(
        setReportFilter(
          {
            ...currentFilter.data,
            businesses: currentFilter.data.businesses.map((item) => item.id),
          },
          title
        )
      );
    }
    return dispatch(setReportFilter({ ...model }, title));
  };

  const onChangeDatePickerStart = (value) => {
    setModel({
      ...model,
      start_date: value,
    });
  };

  const onChangeDatePickerEnd = (value) => {
    setModel({
      ...model,
      end_date: value,
    });
  };

  useEffect(() => {
    if (!model.start_date) {
      handleFormErrors(true);
      handleStartDateError(t('field_is_required_'));
    } else {
      if (new Date(model.start_date) == 'Invalid Date') {
        handleFormErrors(true);
        handleStartDateError('The date format is invalid');
      } else if (
        new Date(model.start_date).getTime() >
          new Date(model.end_date).getTime() &&
        model.end_date
      ) {
        handleFormErrors(true);
        handleStartDateError('The start date cannot be after the end date');
      } else {
        handleStartDateError('');
      }
    }

    if (!model.end_date) {
      handleFormErrors(true);
      handleEndDateError(t('help.field_is_required'));
    } else {
      if (new Date(model.end_date) == 'Invalid Date') {
        handleFormErrors(true);
        handleEndDateError(t('the_date_format_is_invalid_'));
      } else if (
        new Date(model.end_date).getTime() <
          new Date(model.start_date).getTime() &&
        model.start_date
      ) {
        handleFormErrors(true);
        handleEndDateError('The end date cannot be before the start date');
      } else {
        handleEndDateError('');
      }
    }
  }, [model]);

  const handleSelectType = (e, data) => {
    e.stopPropagation();
    setCurrentFilter(data);
    return setModel({
      ...data,
      businesses: data.businesses.map((item) => item.id),
    });
  };

  return (
    <div className="report-group">
      <FormGroup>
        <Box sx={{ gap: '32px 16px', mb: '20px' }} className="d-flex flex-wrap">
          <FormControl
            id="report-type"
            sx={{
              width: '300px',
              '#report-type-select': {
                paddingY: '8px !important',
                paddingLeft: '12px !important',
              },
            }}
          >
            <InputLabel
              id="simple-select-label"
              sx={{ backgroundColor: 'white', paddingX: '8px' }}
            >
              {t('reports_settings.report_type')}
              <span className="required-icon">*</span>
            </InputLabel>
            <Select
              id="report-type-select"
              name="type"
              labelId="simple-select-label"
              value={type}
              onChange={handleModelField}
            >
              <MenuItem className="report-title" disabled={true}>
                {t('reports_settings.report_type')}
              </MenuItem>
              <MenuItem value={'SALES_BY_DATE'}>
                {t('reports_settings.sales_by_date')}
              </MenuItem>
              <MenuItem value={'TOP_SELLERS'}>
                {' '}
                {t('reports_settings.top_seller_items')}
              </MenuItem>
              <MenuItem value={'SUMMARY'}>
                {' '}
                {t('reports_settings.summary_report')}{' '}
              </MenuItem>
              <MenuItem value={'SALES_BY_PROVIDER'}>
                {t('reports_settings.detailed_sales_provider')}
              </MenuItem>
              <MenuItem value={'SALES_BY_HOUR'}>
                {' '}
                {t('reports_settings.sales_by_hour')}
              </MenuItem>
              <MenuItem value={'DETAILED_ORDER_REPORT'}>
                {t('reports_settings.detailed_order_report')}
              </MenuItem>
              <MenuItem value={'CUSTOMER_REPORT'}>
                {' '}
                {t('reports_settings.customer_report')}
              </MenuItem>
              <MenuItem value={'SUBSCRIBER_REPORT'}>
                {' '}
                {t('reports_settings.subscriber_report')}
              </MenuItem>
              {!checkPermissionPlan(all['EXTERNAL_BUNDLE']) && (
                <MenuItem value={'CHARGEBACK_DISPUTE_REPORT'}>
                  {' '}
                  {t('reports_settings.dispute_report')}
                </MenuItem>
              )}
              <Divider />
              {filterList.length > 0 && (
                <MenuItem className="report-title" disabled>
                  {t('reports_settings.customer_report')}
                </MenuItem>
              )}
              {filterList.map((item) => (
                <MenuItem
                  onClick={(e) => handleSelectType(e, item.data)}
                  data={JSON.stringify(item)}
                  key={item?.id || uuidv4()}
                  value={`${item?.name} (${handleTypeName(item?.data?.type)})`}
                  dataid={item.id}
                >
                  {item?.name} ({t(handleTypeName(item?.data?.type))})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '16px',
                }}
              >
                <FormControl>
                  <Box
                    sx={{ maxWidth: '180px' }}
                    id="start-date-wrapper"
                    className="start-date-wrapper pl-0"
                  >
                    {startDateError && (
                      <Tooltip title={startDateError} placement="top" arrow>
                        <img
                          width={16}
                          height={16}
                          alt="Date Issue Icon"
                          src={dateIssue}
                          style={{
                            top: '12px',
                            position: 'absolute',
                            zIndex: 1,
                            left: '13.5px',
                          }}
                        />
                      </Tooltip>
                    )}
                    <DatePicker
                      name="start_date"
                      onChange={onChangeDatePickerStart}
                      value={start_date}
                      sx={{
                        input: {
                          padding: `8px 0 8px ${
                            startDateError ? '35px' : '12px'
                          }`,
                        },
                        fieldset: {
                          borderColor: startDateError
                            ? '#E21508 !important'
                            : '',
                        },
                      }}
                      label={
                        <span
                          style={{
                            backgroundColor: '#FFF',
                            color: `${endDateError ? '#E21508' : ''}`,
                          }}
                        >
                          {t('reports_settings.start_date')}
                          <span style={{ color: '#E21508' }}>*</span>
                        </span>
                      }
                    />
                  </Box>
                </FormControl>
                <FormControl>
                  <Box
                    sx={{ maxWidth: '180px' }}
                    id="end-date-wrapper"
                    className="end-date-wrapper pr-0"
                  >
                    {endDateError && (
                      <Tooltip title={endDateError} placement="top" arrow>
                        <img
                          width={16}
                          height={16}
                          alt="Date Issue Icon"
                          src={dateIssue}
                          style={{
                            top: '12px',
                            position: 'absolute',
                            zIndex: 1,
                            left: '13.5px',
                          }}
                        />
                      </Tooltip>
                    )}
                    <DatePicker
                      name="end_date"
                      value={end_date}
                      onChange={onChangeDatePickerEnd}
                      sx={{
                        input: {
                          padding: `8px 0 8px ${
                            endDateError ? '35px' : '12px'
                          }`,
                        },
                        fieldset: {
                          borderColor: endDateError ? '#E21508 !important' : '',
                        },
                      }}
                      label={
                        <span
                          style={{
                            backgroundColor: '#FFF',
                            color: `${endDateError ? '#E21508' : ''}`,
                          }}
                        >
                          {t('reports_settings.end_date')}
                          <span style={{ color: '#E21508' }}>*</span>
                        </span>
                      }
                    />
                  </Box>
                </FormControl>
              </Box>
            </LocalizationProvider>
          </FormControl>
          <FormControl
            id="days-of-week"
            sx={{
              '#day-checkbox': {
                paddingY: '8px !important',
                paddingLeft: '12px !important',
              },
              minWidth: '220px',
            }}
          >
            {days_of_week.length === 0 && (
              <Tooltip
                title={t('help.field_is_required')}
                placement="top"
                arrow
              >
                <img
                  width={16}
                  height={16}
                  alt="Date Issue Icon"
                  src={dateIssue}
                  style={{
                    top: '12px',
                    position: 'absolute',
                    zIndex: 1,
                    left: '13.5px',
                  }}
                />
              </Tooltip>
            )}
            <InputLabel
              sx={{
                backgroundColor: 'white',
                paddingX: '8px',
                color: days_of_week.length === 0 ? '#E21508 !important' : '',
              }}
              id="day-checkbox-label"
            >
              {' '}
              {t('reports_settings.days_of_week')}
              <span className="required-icon">*</span>
            </InputLabel>
            <Select
              name="days_of_week"
              labelId="day-checkbox-label"
              id="day-checkbox"
              multiple
              value={days_of_week}
              onChange={handleModelField}
              error={days_of_week.length === 0}
              renderValue={(selected) => {
                const foundDay = allDays.find(
                  (item) => item.value === selected[0]
                )?.label;
                return (
                  <>
                    {foundDay && t(foundDay)}
                    {selected.length > 1 && (
                      <span className="font-16 render-title">
                        ( + {selected.length - 1} {t('help.other')})
                      </span>
                    )}
                  </>
                );
              }}
            >
              <MenuItem value={'checkAll'}>
                <Checkbox
                  name="allCheck"
                  checked={days_of_week.length === allDays.length}
                  value={days_of_week.length}
                />
                <ListItemText primary={t('reports_settings.select_all')} />
              </MenuItem>
              {allDays.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <Checkbox checked={days_of_week.indexOf(item.value) > -1} />
                  <ListItemText primary={t(item.label)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            id="providers"
            sx={{
              '#provider-checkbox': {
                paddingY: '8px !important',
                paddingLeft: '12px !important',
              },
              minWidth: '260px',
            }}
          >
            {providers.length === 0 && (
              <Tooltip
                title={t('help.field_is_required')}
                placement="top"
                arrow
              >
                <img
                  width={16}
                  height={16}
                  alt="Date Issue Icon"
                  src={dateIssue}
                  style={{
                    top: '12px',
                    position: 'absolute',
                    zIndex: 1,
                    left: '13.5px',
                  }}
                />
              </Tooltip>
            )}
            <InputLabel
              sx={{
                backgroundColor: 'white',
                paddingX: '8px',
                color: providers.length === 0 ? '#E21508 !important' : '',
              }}
              id="provider-checkbox-label"
            >
              {t('reports_settings.providers')}
              <span className="required-icon">*</span>
            </InputLabel>
            <Select
              name="providers"
              labelId="provider-checkbox-label"
              id="provider-checkbox"
              multiple
              onOpen={handleMenuOpen}
              value={providers}
              onChange={handleModelField}
              error={providers.length === 0}
              renderValue={(selected) => {
                const providerValue =
                  providersConfig[selected[0]]?.label.toUpperCase();
                return (
                  <>
                    {selected.length && providerValue}
                    {selected.length > 1 && (
                      <span className="font-16 render-title">
                        ( + {selected.length - 1}&nbsp;
                        {t('help.other')} )
                      </span>
                    )}
                  </>
                );
              }}
              MenuProps={{
                PaperProps: {
                  ref: dropdownRef,
                  style: {
                    maxHeight: 400,
                    overflowY: 'auto',
                  },
                },
              }}
            >
              {allProviders.length > 1 && (
                <MenuItem value="checkAll">
                  <Checkbox
                    name="allCheck"
                    checked={providers.length === allProviders.length}
                    value={providers.length}
                  />
                  <ListItemText primary={t('reports_settings.select_all')} />
                </MenuItem>
              )}
              {allProviders.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={providers.indexOf(name) > -1} />
                  <ListItemText
                    primary={providersConfig[name]?.label.toUpperCase()}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {model.type === CHARGEBACK_DISPUTE_REPORT &&
          allBusinessGroups.length > 0 ? (
            <Box sx={{ position: 'relative', zIndex: 3 }}>
              <BusinessGroup
                businessGroups={allBusinessGroups}
                allLocations={allLocations}
                allBrands={convertedBrand}
              />
            </Box>
          ) : null}
        </Box>
        <Box
          sx={{ gap: '20px 16px', mb: 2 }}
          className="d-flex flex-wrap align-items-center"
        >
          <Box>
            <CustomBrandAutoCompleteReport
              convertedBrand={convertedBrand}
              type={model.type}
            />
            {type === 'SALES_BY_PROVIDER' && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={breakdownBrand}
                    onChange={(event) => {
                      setModel({
                        ...model,
                        breakDown:
                          breakdownLocation && event.target.checked
                            ? BreakDownEnum.BRAND_AND_LOCATION
                            : event.target.checked
                            ? BreakDownEnum.BRAND
                            : breakdownLocation
                            ? BreakDownEnum.LOCATION
                            : '',
                      });
                      setBreakdownBrand(event.target.checked);
                    }}
                  />
                }
                label={t('Breakdown by brand')}
                name="breakdownBrand"
              />
            )}
          </Box>
          <Box>
            <LocationAutoCompleteReport
              locationsList={allLocations}
              isError={locationIdsForReport.length === 0}
              type={model.type}
            />
            {type === 'SALES_BY_PROVIDER' && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={breakdownLocation}
                    onChange={(event) => {
                      setModel({
                        ...model,
                        breakDown:
                          breakdownBrand && event.target.checked
                            ? BreakDownEnum.BRAND_AND_LOCATION
                            : event.target.checked
                            ? BreakDownEnum.LOCATION
                            : breakdownBrand
                            ? BreakDownEnum.BRAND
                            : '',
                      });
                      setBreakdownLocation(event.target.checked);
                    }}
                  />
                }
                name="breakdownLocation"
                label={t('Breakdown by location')}
              />
            )}
          </Box>
        </Box>
        <div className="justify-content-end group-btn">
          <FilterDialog
            formErrors={formErrors}
            handleSaveFilter={handleSaveFilter}
          />
          <ButtonWrapper
            id="filtersReport"
            onClick={handleSubmit}
            variant="contained"
            type="submit"
            color="primary"
            className={`banner-btn banner-view ${
              (formErrors || startDateError || endDateError) && 'disable-view'
            }`}
            disabled={
              Boolean(formErrors) ||
              Boolean(startDateError) ||
              Boolean(endDateError) ||
              Boolean(locationIdsForReport.length === 0) ||
              Boolean(brandIdsForReport.length === 0)
            }
          >
            {t('reports_settings.view_report')}
          </ButtonWrapper>
        </div>
      </FormGroup>
    </div>
  );
};

export default FilterReport;
