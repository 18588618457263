import { INTEGRATIONS_TITLES, PAGE_TITLES, SETTINGS_TITLES, TABLE_ORDERING } from 'constants/pageTitles';
import { t } from 'i18next';

export const getTitle = (pathName) => {
  let result;
  Object.keys(PAGE_TITLES).forEach((item) => {
    if (pathName && pathName.includes(item)) {
      if (pathName.includes('website')) {
        const isMainTab = pathName.includes('main');
        const isCouponTab = pathName.includes('coupons');
        const isRewardsTab = pathName.includes('rewards');

        if (isMainTab) {
          result = `${t(PAGE_TITLES.website)} / ${t(PAGE_TITLES.main)}`;
        }
        if (isCouponTab) {
          result = `${t(PAGE_TITLES.website)} / ${t(PAGE_TITLES.coupons)}`;
        }
        if (isRewardsTab) {
          result = `${t(PAGE_TITLES.website)} / ${t(PAGE_TITLES.rewards)}`;
        }
      } else {
        result = t(PAGE_TITLES[item]);
      }
    } else if (pathName === '/' || pathName === '/Dashboards') {
      // result = `sidebar_header.${item}`;
      result = t(PAGE_TITLES.home);
    }
    return false;
  });

  Object.keys(SETTINGS_TITLES).forEach((item) => {
    if (pathName && pathName.includes(item)) {
      result = `settings_title.${item}`;
      // result = SETTINGS_TITLES[item];
    }
  });

  if (pathName.includes('/table-ordering')) {
    Object.keys(TABLE_ORDERING).forEach((item) => {
      if (pathName && pathName.includes(item)) {
        result = `table_ordering_title.${item}`;
      }
    });
  }

  if (pathName.includes('/integrations')) {
    Object.keys(INTEGRATIONS_TITLES).forEach((item) => {
      if (pathName && pathName.includes(item)) {
        result = `${t(INTEGRATIONS_TITLES[item])}`;
      }
    });
  }
  return result;
};
