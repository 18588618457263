import { useEffect, useState } from 'react';
import usePermission from 'hooks/permission';
import * as all from 'constants/permissions';
import BrandListItems from './BrandListItems';
import { SupportLabel } from 'pages/Report/styles';
import LocationListItems from './LocationListItems';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import WrapperList from 'components/BusinessList/WrapperList';
import { ChildContainer, MainContainer, SearchField } from './styles';
import { useHistory, useLocation } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  getBrandList,
  getCurrentBusiness,
  updateCurrentBusiness,
} from 'redux/actions/business';
import Preloader from '../Preloader';
import { useTranslation } from 'react-i18next';
import { confirm } from 'components/MenuNew/ConfirmModal';
import { SET_SHOW_ACTIVE_BUSINESS } from '../../redux/actions/types';

// import { t } from 'i18next';
const BusinessNewListBox = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const checkPermission = usePermission();
  const {
    currentBusiness,
    brandList,
    currentSelectedBrand,
    isBrandLoading,
    allBrandList,
    showOnlyActiveBusinesses,
    allBrandListFiltered,
  } = useSelector(({ business }) => business);
  const [currentActiveBrandList, setCurrentActiveBrandList] = useState(
    brandList || []
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const initialSelectedBrand = localStorage.getItem('selectedBrand');
  const initialSelectedLocation = localStorage.getItem('selectedLocation');
  const { formValid } = useSelector(({ menu }) => menu);
  const [lastSelected] = useState(
    JSON.parse(localStorage.getItem('businessInfo'))
  );
  const [selectedBrand, setSelectedBrand] = useState(
    initialSelectedBrand ? JSON.parse(initialSelectedBrand) : null
  );
  const [selectedLocation, setSelectedLocation] = useState(
    initialSelectedLocation ? JSON.parse(initialSelectedLocation) : null
  );
  const [selectedId, setSelectedId] = useState(null);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [brandLocationChange, setBrandLocationChange] = useState('');
  const brandFirst = currentActiveBrandList?.filter(
    (item, index) => index < 10
  );
  const [brandData, setBranData] = useState(brandFirst);
  const locationFirst = selectedBrand?.value?.locations?.filter(
    (item, index) => index < 100
  );
  const [brandLocationData, setBrandLocationData] = useState(locationFirst);
  const [isSuperAdminHasSearch, setIsSuperAdminHasSearch] = useState(false);
  useEffect(() => {
    dispatch(getBrandList());
  }, []);
  const handleChange = (event) => {
    const checked = event.target.checked ? 1 : 0;
    localStorage.setItem('showActiveBusiness', checked);
    setSelectedId(null);
    setSelectedLocationId(null);
    dispatch({ type: SET_SHOW_ACTIVE_BUSINESS, payload: event.target.checked });
    setIsSuperAdminHasSearch(false);
    setSearchValue('');
    const currentBrandList = event.target.checked
      ? allBrandListFiltered
      : allBrandList;
    setCurrentActiveBrandList(currentBrandList);
    const brandFirst = currentBrandList?.filter((item, index) => index < 10);
    setBranData(brandFirst);
    if (brandFirst.length > 0) {
      const currentSelectedBrand = {
        id: brandFirst[0]?._id,
        name: brandFirst[0]?.brandName,
        label: brandFirst[0]?.brandName,
        value: brandFirst[0],
      };
      setSelectedBrand(currentSelectedBrand);
      const locationFirst = currentSelectedBrand?.value?.locations?.filter(
        (item, index) => index < 100
      );
      setBrandLocationData(locationFirst);
      if (currentSelectedBrand?.value?.locations?.length > 0) {
        const firstLocation = currentSelectedBrand.value.locations[0];
        const modelSelectedLocation = {
          value: firstLocation,
          label: firstLocation?.locationName,
          name: firstLocation?.locationName,
          verified: `${firstLocation?.verified}`,
        };
        setSelectedLocation(modelSelectedLocation);
        setSelectedLocationId(modelSelectedLocation?.value?._id);
      }
    }
  };

  useEffect(() => {
    if (checkPermission(all['SUPER'])) {
      if (brandList && showOnlyActiveBusinesses) {
        setCurrentActiveBrandList(allBrandListFiltered);
      } else if (allBrandList && !showOnlyActiveBusinesses) {
        setCurrentActiveBrandList(allBrandList);
      }
    } else {
      setCurrentActiveBrandList(allBrandList);
    }
  }, [allBrandList, brandList, currentBusiness]);

  useEffect(() => {
    if (formValid) {
      if (currentBusiness.id !== lastSelected.id) {
        dispatch(
          updateCurrentBusiness({
            ...lastSelected,
            label: `${lastSelected.companyName} - ${
              lastSelected.addressInfo.address1 ||
              lastSelected.addressInfo.address ||
              ''
            }`,
          })
        );
      }
    }
  }, [formValid]);

  const brandOnChange = (brand, isDevelopmentMode = false, indexOf = 0) => {
    if (!isDevelopmentMode) {
      if (
        !brand?.value?._id ||
        brand?.value?._id !== selectedBrand?.value?._id
      ) {
        const modelSelectedLocation = {
          value: brand?.value?.locations[0],
          label: brand?.value?.locations[0]?.locationName,
          name: `${brand?.value?.locations[0]?.locationName}`,
          disabled: `${brand?.value?.locations[0]?.disabled}`,
          verified: `${brand?.value?.locations[0]?.verified}`,
          classifications: `${brand?.value?.locations[0]?.classifications}`,
        };
        setSelectedLocation(modelSelectedLocation);
        setSelectedLocationId(null);
        setSelectedBrand(brand);
      }
    } else if (isDevelopmentMode) {
      const modelSelectedLocation = {
        value: brand?.locations[indexOf],
        label: brand?.locations[indexOf]?.locationName,
        name: `${brand?.locations[indexOf]?.locationName}`,
      };
      setSelectedBrand(brand);
      setSelectedLocation(modelSelectedLocation);
      setSelectedLocationId(null);
      setSelectedId(null);
    }
  };

  useEffect(() => {
    if (currentSelectedBrand && Object.keys(currentSelectedBrand).length > 0) {
      brandOnChange(currentSelectedBrand);
      setSelectedBrand(currentSelectedBrand);
    }
  }, [currentSelectedBrand]);

  const locationOnChange = (location) => {
    setSelectedLocation(location);
  };

  const handleOpen = (event) => {
    setBrandLocationData(locationFirst);
    setBranData(brandFirst);
    setAnchorEl(event.currentTarget);
    const location = JSON.parse(initialSelectedLocation);
    if (location) {
      locationOnChange(location);
    } else {
      const modelSelectedLocation = {
        value: selectedBrand?.value?.locations[0],
        label: selectedBrand?.value?.locations[0]?.locationName,
        name: `${selectedBrand?.value?.locations[0]?.locationName}`,
        disabled: `${selectedBrand?.value?.locations[0]?.disabled}`,
        verified: `${selectedBrand?.value?.locations[0]?.verified}`,
        classifications: `${selectedBrand?.value?.locations[0]?.classifications}`,
      };
      locationOnChange(modelSelectedLocation);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setBrandLocationChange('');
    setSearchValue('');
    setIsSuperAdminHasSearch(false);
    setSkip(10);
    const location = JSON.parse(initialSelectedLocation);
    setSelectedBrand(JSON.parse(initialSelectedBrand));
    setSelectedLocation(location);
    setSelectedLocationId(
      initialSelectedBrand?.value?.locations?.[0]?.businessId
    );
    brandOnChange(JSON.parse(initialSelectedBrand));
    setSelectedId(null);
    setBrandChange(null);
  };
  const limit = 10;
  const [skip, setSkip] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const [skipSearch, setSkipSearch] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [brandChange, setBrandChange] = useState('');
  const [filteredLength, setFilteredLength] = useState(0);
  const [locationChange, setLocationChange] = useState([]);
  const [hasSearchMore, setHasSearchMore] = useState(false);
  const [hasMoreLocation, setHasMoreLocation] = useState(true);
  const [hasMoreSearchLocation, setHasSearchMoreLocation] = useState(false);
  const [filteredLengthLocation, setFilteredLengthLocation] = useState(0);

  function sanitizeString(str, toLowerCase = true, trim = true) {
    if (!str || typeof str !== 'string') {
      return '';
    }

    let sanitizedString = str.replace(/[^a-zA-Z0-9@.]/g, '');
    sanitizedString = toLowerCase
      ? sanitizedString.toLowerCase()
      : sanitizedString;
    return trim ? sanitizedString.trim() : sanitizedString;
  }

  const handleSearch = (event) => {
    const searchTerm = sanitizeString(event.target.value);
    setSearchValue(event.target.value);
    setIsSuperAdminHasSearch(true);
    let searchedBrand = currentActiveBrandList.map((business) => {
      const matchInBusinessName = sanitizeString(business?.brandName).includes(
        searchTerm
      );
      const matchingLocations = business?.locations.filter((location) => {
        return Object.values(location).some((value) =>
          sanitizeString(value).includes(searchTerm)
        );
      });

      return {
        ...business,
        locations: matchInBusinessName
          ? business?.locations
          : matchingLocations,
        matchInBusinessName,
        matchInLocations: matchingLocations.length > 0,
      };
    });

    if (!searchedBrand.length) {
      setIsSuperAdminHasSearch(false);
    }

    let newValue = searchedBrand
      .filter(
        (business) => business.matchInBusinessName || business.matchInLocations
      )
      .sort((a, b) => {
        if (a.matchInBusinessName && !b.matchInBusinessName) return -1;
        if (!a.matchInBusinessName && b.matchInBusinessName) return 1;

        const aMatchIndex = sanitizeString(a?.brandName, true, true).indexOf(
          searchTerm
        );
        const bMatchIndex = sanitizeString(b?.brandName, true, true).indexOf(
          searchTerm
        );
        const aMatchPercentage = searchTerm.length / (a.brandName.length || 1);
        const bMatchPercentage = searchTerm.length / (b.brandName.length || 1);

        if (aMatchIndex === bMatchIndex) {
          return bMatchPercentage - aMatchPercentage;
        }
        return aMatchIndex - bMatchIndex;
      });

    setBrandLocationData(newValue[0]?.locations);
    setBranData(newValue);
    brandOnChange(newValue[0], true, 0);
    let elem = document.querySelectorAll('.infinite-scroll-component');
    elem[0]?.scrollTo({
      left: 0,
      top: 0,
    });
  };

  const fetchMoreData = () => {
    if (isSuperAdminHasSearch) return;
    if (brandData.length > currentActiveBrandList?.length) {
      setHasMore(false);
      return;
    }
    if (hasSearchMore) {
      setTimeout(() => {
        if (filteredLength > brandData.length) {
          const newData = brandData.slice(skipSearch, skipSearch + limit);
          setBranData(brandData.concat([...newData]));
          setSkipSearch(skipSearch + limit);
          setSkip(0);
        }
      }, 500);
    } else {
      setTimeout(() => {
        const newData = currentActiveBrandList.slice(skip, skip + limit);
        setBranData(brandData.concat([...newData]));
        setSkip(skip + limit);
      }, 500);
      setSkipSearch(0);
    }
  };

  const fetchMoreDataLocation = () => {
    if (isSuperAdminHasSearch) return;
    if (brandLocationData.length >= selectedBrand?.value?.locations?.length) {
      setHasMoreLocation(false);
      return;
    }
    if (hasMoreSearchLocation) {
      if (filteredLengthLocation > brandLocationData.length) {
        setTimeout(() => {
          const newData = [];
          locationChange?.value?.locations?.forEach((item, index) => {
            if (index + 10 > brandLocationData.length) {
              if (newData?.length < 10) {
                newData.push(item);
              }
            }
          });
          setBrandLocationData(brandLocationData.concat([...newData]));
        }, 1500);
      }
    } else {
      setTimeout(() => {
        const newData = [];
        selectedBrand?.value?.locations?.forEach((item, index) => {
          if (index + 10 > brandLocationData.length) {
            if (newData?.length < 10) {
              newData.push(item);
            }
          }
        });
        setBrandLocationData(brandLocationData.concat([...newData]));
      }, 1500);
    }
  };

  const selectBrand = (item) => {
    setBrandLocationChange('');
    if (!item?._id || item?._id !== selectedBrand?.value?._id) {
      const modelSelectedLocation = {
        value: item.locations[0],
        label: item.locations[0].locationName,
        name: `${item.locations[0].locationName}`,
        disabled: `${item.locations[0]?.disabled}`,
        verified: `${item.locations[0]?.verified}`,
        classifications: `${item.locations[0]?.classifications}`,
      };
      setSelectedLocation(modelSelectedLocation);
      const modelLocation = {
        value: {
          brandName: item.brandName,
          locations: item.locations,
        },
        label: item.locations[0].locationName,
        name: `${item.locations[0].locationName}`,
        disabled: `${item.locations[0]?.disabled}`,
        verified: `${item.locations[0]?.verified}`,
        classifications: `${item.locations[0]?.classifications}`,
      };
      const modelSelectedLocation2 = [];
      item.locations?.forEach((location) => {
        modelSelectedLocation2.push({
          address: location?.address || '',
          businessId: location?.businessId,
          locationName: location?.locationName,
          disabled: location?.disabled,
          verified: location?.verified,
          classifications: location?.classifications,
          _id: location?._id,
        });
      });
      setBrandLocationData(modelSelectedLocation2);
      setSelectedBrand(modelLocation);
    }
    setSelectedId(item._id);
    setSelectedLocationId(null);
  };

  const selectedLocations = (item, id) => {
    const findCurrentLocation =
      'value' in selectedBrand
        ? selectedBrand?.value?.locations.find((loc) => loc?.businessId === id)
        : selectedBrand?.locations.find((loc) => loc?.businessId === id);
    const modelSelectedLocation = {
      value: findCurrentLocation,
      label: findCurrentLocation?.locationName,
      name: `${findCurrentLocation?.locationName}`,
      disabled: `${findCurrentLocation?.disabled}`,
      verified: `${findCurrentLocation?.verified}`,
      classifications: `${findCurrentLocation?.classifications}`,
    };
    setSelectedLocation(modelSelectedLocation);
    setSelectedLocationId(item.businessId);
  };

  const handleSubmit = () => {
    localStorage.removeItem('alreadyLoaded');
    history.push(history.location.pathname);
    localStorage.removeItem('currentTab');
    if (selectedLocation && selectedLocation?.value?.businessId) {
      localStorage.setItem(
        'selectedLocation',
        JSON.stringify(selectedLocation)
      );
      localStorage.setItem('selectedBrand', JSON.stringify(selectedBrand));
      dispatch(getCurrentBusiness(selectedLocation?.value?.businessId));
      localStorage.setItem(
        'businessInfo',
        JSON.stringify({
          label: ``,
          id: selectedLocation?.value?.businessId,
        })
      );
      setAnchorEl(null);
      handleClose();
      if (
        location.pathname.includes('/integrations/providers') &&
        location.pathname.includes('settings')
      ) {
        history.push('/integrations/providers');
      }

      const handleSaveData = async () => {
        const confirmed = confirm({
          hideCloseIcon: false,
          hideDiscardBtn: false,
          onClose: () => {},
        });
        if (!confirmed) {
          if (document.getElementById('productSaveButton')) {
            dispatch({ type: 'FORM_VALIDITY', payload: true });
            return document.getElementById('productSaveButton').click();
          }
          if (document.getElementById('saveButton')) {
            dispatch({ type: 'FORM_VALIDITY', payload: true });
            return document.getElementById('saveButton').click();
          }
        } else {
          if (
            location.pathname.includes('menu') &&
            location.pathname.includes('main')
          ) {
            window.location.href = '/menu/main';
          } else {
            window.location.reload();
          }
        }
      };
      if (
        !(
          (location.pathname.includes('menu') &&
            location.pathname.includes('edit')) ||
          location.pathname.includes('add-category') ||
          location.pathname.includes('add-product')
        ) ||
        location.pathname.includes('doordash') ||
        location.pathname.includes('website') ||
        location.pathname.includes('grubhub')
      ) {
        if (
          location.pathname.includes('menu') &&
          location.pathname.includes('main')
        ) {
          window.location.href = '/menu/main';
        } else {
          window.location.reload();
        }
      } else {
        if (currentBusiness._id) {
          handleSaveData();
        }
      }
      if (location.pathname.includes('schedule/new')) {
        window.location.href = '/schedule/';
      }
    }
    if (location.pathname.includes('menu')) {
      window.location.href = window.location.pathname;
    }
  };

  function replaceSymbolsWithoutEmail(input) {
    return input.replace(
      /[{}[\]'"^|:\/)(\-_=+!$%#&*|\\~`“…«<>?,.@√çƒ√∂ƒ©˙œ∑´®†¥¨ˆøπß∂ƒ©˙∆˚¬Ω≈ç√∫˜µ≤≥÷∞§¶•ªº–≠¡™¢£]/g,
      ''
    );
  }

  const handleBrandChange = (event) => {
    const { value } = event.target;
    let values = replaceSymbolsWithoutEmail(value);
    setBrandChange(values);
    const filterData = [];
    currentActiveBrandList.forEach((item) => {
      if (item.brandName?.toLowerCase()?.includes(values?.toLowerCase())) {
        filterData.push(item);
      }
    });
    if (!values) {
      setHasSearchMore(false);
      setBrandChange('');
      setBranData(brandFirst);
      let elem = document.querySelectorAll('.infinite-scroll-component');
      elem[0]?.scrollTo({
        left: 0,
        top: 0,
      });
      setSkipSearch(0);
    } else {
      setHasSearchMore(true);
      setBrandChange(values);
      setBranData(filterData);
      setFilteredLength(filterData.length);
      brandOnChange(filterData[0], true, 0);
      setSkipSearch(1);
      setSkip(10);
    }
  };

  const handleLocationChange = (event) => {
    const { value } = event.target;
    const values = replaceSymbolsWithoutEmail(value);
    setBrandLocationChange(values);
    const filterData = [];
    selectedBrand?.value?.locations?.forEach((item) => {
      if (
        item.locationName?.toLowerCase()?.includes(values?.toLowerCase()) ||
        item.address?.toLowerCase()?.includes(values?.toLowerCase())
      ) {
        filterData.push(item);
      }
    });
    if (!values) {
      setHasSearchMoreLocation(false);
      setLocationChange(locationFirst);
      setBrandLocationData(locationFirst);
      setBrandLocationChange('');
      setFilteredLengthLocation(0);
      setSkipSearch(0);
      let elem = document.querySelectorAll('.location-scroll');
      elem[0]?.scrollTo({
        left: 0,
        top: 0,
      });
    } else {
      setHasSearchMoreLocation(true);
      setLocationChange(filterData);
      setBrandLocationData(filterData);
      setFilteredLengthLocation(filterData.length);
      setBrandLocationChange(values);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };
  return (
    <>
      <Button
        sx={{
          width: '340px',
          maxWidth: '540px',
          height: '40px',
          display: 'flex',
          justifyContent: 'space-between',
          border: '1px solid #E7E7E7',
          color: '#919191',
          textAlign: 'left',
        }}
        id="businessNewListAll"
        aria-describedby={id}
        variant="outlined"
        disabled={
          isBrandLoading ||
          (!currentBusiness && Object.keys(currentBusiness).length === 0)
        }
        endIcon={open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        onClick={handleOpen}
      >
        <Typography noWrap fontSize={'14px'} color={'#333333'}>
          {isBrandLoading && <Preloader />}
          {currentBusiness &&
          Object.keys(currentBusiness).length > 0 &&
          !isBrandLoading
            ? `${currentBusiness?.brandInfo?.brandName} - ${currentBusiness?.addressInfo?.address1}`
            : t('All Businesses')}
        </Typography>
      </Button>
      <WrapperList
        id={id}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      >
        <MainContainer>
          {checkPermission(all['SUPER']) && (
            <Box sx={{ padding: '20px' }}>
              <SupportLabel>{t('header.search_for_support')} </SupportLabel>
              <SearchField
                multiline
                onKeyDown={handleKeyDown}
                onChange={(e) => handleSearch(e)}
                placeholder={t('header.search_for_support_...')}
                value={searchValue}
                variant="outlined"
                size="small"
              />
            </Box>
          )}
          <ChildContainer>
            <BrandListItems
              onKeyDown={handleKeyDown}
              handleBrandChange={handleBrandChange}
              brandChange={brandChange}
              brandData={brandData}
              fetchMoreData={fetchMoreData}
              selectBrand={selectBrand}
              selectedBrand={selectedBrand}
              selectedId={selectedId}
              hasMore={hasMore}
            />
            <LocationListItems
              onKeyDown={handleKeyDown}
              brandLocationData={brandLocationData}
              handleLocationChange={handleLocationChange}
              brandLocationChange={brandLocationChange}
              setBrandLocationChange={setBrandLocationChange}
              selectedBrand={selectedBrand}
              selectedLocation={selectedLocation}
              currentBusiness={currentBusiness}
              selectedLocations={selectedLocations}
              selectedLocationId={selectedLocationId}
              hasMoreLocation={hasMoreLocation}
              fetchMoreData={fetchMoreDataLocation}
            />
          </ChildContainer>
          {checkPermission(all['SUPER']) && (
            <Box sx={{ padding: '0 20px' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showOnlyActiveBusinesses}
                      onChange={handleChange}
                    />
                  }
                  label="Show only active businesses"
                />
              </FormGroup>
            </Box>
          )}
          <Box>
            <Divider />
            <Box sx={{ p: 2, textAlign: 'right' }}>
              <Button
                id="businessList"
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                {t('orders_history.apply')}
              </Button>
            </Box>
          </Box>
        </MainContainer>
      </WrapperList>
    </>
  );
};

export default BusinessNewListBox;
