import { useState, useEffect, useCallback, useContext } from 'react';
import instance from 'services/api';
import { BackButton } from './styles';
import confirm from 'components/Confirm';
import BusinessSelect from './BusinessSelect';
import { useHistory } from 'react-router-dom';
import { trialVersionDate } from 'utils/soldOut';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import SubscriptionPlans from 'components/SubscriptionPlans';
import CustomCheckbox from 'components/Forms/CustomCheckbox';
import CircularProgress from '@mui/material/CircularProgress';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { SIGN_UP_FLOW_ADD_LOCATION } from 'constants/availableButtons';
import { addBrandFormSchema, modalBrandFormSchema } from './schema';
import DialogAddSelectItem from './DialogAddItem/DialogAddSelectItem';
import CustomSelectAddItem from 'components/Forms/CustomSelectAddItem';
import GooglAutocomplete from 'components/FormFields/GoogleAutocomplate';
import { Box, Button, Grid, Typography } from '@mui/material';
import { convertForMaterialTable } from 'utils/business';
import { PaymentPeriodicityContext } from 'context/paymentPeriodicityContext';
import ApplyCoupon from 'components/ApplyCoupon/ApplyCoupon';
import AcceptRequirements from 'components/ApplyCoupon/AcceptRequirements';
import { useTranslation } from 'react-i18next';
import { getBusinessSettings } from 'redux/actions/business';
import LabelRequired from 'components/FormFields/LabelRequired';
import { SubscriptionContext } from 'context/subscriptionPlanConfigContext';

const initialValues = {
  brandName: '',
  phoneNumber: '',
  locationName: '',
  cloneMenu: true,
  businessId: JSON.parse(localStorage.getItem('businessInfo'))?.id || '',
  metadata: {
    communicationEmail: '',
  },
};

const AddLocationFirstStep = ({
  handleSubmit,
  data,
  setData,
  getAddressFields,
  loading,
  addressInfo,
  setCurrentBrandId,
  isSpecialLocation = false,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [ids, setIds] = useState('');
  const [businessList, setBusinessList] = useState([]);
  const [selectedBrandName, setSelectedBrandName] = useState('');
  const [selectedPlan, handleSelectedPlan] = useState('');
  const [nextDisabled, setNextDisabled] = useState(true);
  const [selectedPlanPrice, getSelectedPlanPrice] = useState('');
  const [isValid, setIsValid] = useState(false);
  const { periodicity } = useContext(PaymentPeriodicityContext);
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const [couponValue, setCouponValue] = useState('');
  const [isApply, setIsApplay] = useState(false);
  const [checkNewPlans, setCheckNewPlans] = useState(false);
  const { setUpFeeEnabled } = useContext(SubscriptionContext);
  const [acceptAgreeToReceiveOrderCommunication, setAcceptAgreeToReceiveOrderCommunication] = useState(false);
  const [acceptAgreeToReceiveOrderCommunicationError, setAcceptAgreeToReceiveOrderCommunicationError] = useState(false);
  const [agreeToPayRecurringCharges, setAgreeToPayRecurringCharges] = useState(false);
  const [agreeToPayRecurringChargesError, setAgreeToPayRecurringChargesError] = useState(false);
  const [agreeToPaySetupFee, setAgreeToPaySetupFee] = useState(false);
  const [agreeToPaySetupFeeError, setAgreeToPaySetupFeeError] = useState(false);
  const [hasValueForCreation, setHasValueForCreation] = useState('');

  const brandFormMethods = useForm({
    defaultValues: { ...addressInfo, ...initialValues },
    mode: 'all',
    resolver: yupResolver(addBrandFormSchema),
  });

  const modalBrandFormMethods = useForm({
    defaultValues: { brandPhone: '', customBrandName: '' },
    mode: 'all',
    resolver: yupResolver(modalBrandFormSchema),
  });

  const {
    formState: { isSubmitting, isSubmitted, errors },
    control,
    getValues,
  } = brandFormMethods;
  const locationNameWatcher = useWatch({ control, name: 'locationName' });
  const cloneMenu = brandFormMethods.getValues().cloneMenu;

  useEffect(() => {
    getBusinessSettings().then((data) => {
      brandFormMethods.setValue('metadata.communicationEmail', data?.metadata?.communicationEmail);
    });
  }, []);

  useEffect(() => {
    const currentModel = brandFormMethods.getValues();
    currentModel?.locationName !== '' ? setIsValid(true) : setIsValid(false);
  }, [locationNameWatcher]);

  const { reset } = modalBrandFormMethods;

  const brandName = modalBrandFormMethods.getValues().customBrandName;
  useEffect(() => {
    instance
      .get(`/business/all/v2`)
      .then((response) => {
        if (response.data.data) {
          const data = convertForMaterialTable(response.data.data);
          setBusinessList(data);
        }
      })
      .catch(() => {
        setBusinessList([]);
      });
  }, []);

  const handleFormSubmit = (values) => {
    values['oneTimeSetupFeeCouponCode'] = isApply ? couponValue : '' || '';
    values['employeeNumber'] = isApply ? couponValue : '' || '';
    values['agreeToPaySetupFee'] = Number(setUpFeeEnabled) === 1 ? agreeToPaySetupFee : false;
    values['agreeToPayRecurringCharges'] = agreeToPayRecurringCharges;
    values['agreeToReceiveOrderCommunication'] = acceptAgreeToReceiveOrderCommunication;
    // if (!acceptCheckBox) return handleErrorAccept(true);
    if (!values.brandName) values.brandName = brandName;
    values.planId = selectedPlan;
    values.businessId = ids;
    values.paymentTerm = periodicity;
    values.metadata = {
      ...values.metadata,
      addressPlaceId: addressInfo?.placeId,
    };

    handleSubmit(values, SIGN_UP_FLOW_ADD_LOCATION.ADD_LOCATION_SUCCESS);
  };

  const handleClickSave = (value) => {
    const body = {
      brandName: value.customBrandName,
      brandPhone: value.brandPhone,
    };
    setSelectedBrandName(brandName || value.customBrandName);
    brandFormMethods.setValue('brandName', value.customBrandName);
    if (value.customBrandName) {
      brandFormMethods.clearErrors('brandName');
    }
    if (value.customBrandName) {
      instance.post(`/brand`, body).then((res) => {
        setData([...data, res.data.data]);
        setCurrentBrandId(res?.data?.data?._id);
      });
      handleClose();
    }
  };

  const handleAddItem = (value) => {
    let selectedBrand = brandFormMethods.getValues().customBrandName;
    if (selectedBrand || hasValueForCreation) {
      setSelectedBrandName(selectedBrand);
      modalBrandFormMethods.setValue('customBrandName', (value || hasValueForCreation) ?? '');
    }else if (!selectedBrand || !hasValueForCreation) {
      modalBrandFormMethods.setValue('customBrandName', '');
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    modalBrandFormMethods.reset();
    setHasValueForCreation('')
  };
  // const handleAcceptChange = ({ target }) => {
  //   handleAcceptCheckbox(target.checked);
  //   if (!target.checked) {
  //     handleErrorAccept(true);
  //   } else {
  //     handleErrorAccept(false);
  //   }
  // };
  const paymentDate = trialVersionDate();

  const handleClickBack = async () => {
    if (
      Object.keys(brandFormMethods.formState.dirtyFields).length ||
      brandFormMethods.getValues().brandName ||
      brandFormMethods.getValues().address1 ||
      brandFormMethods.getValues().address
    ) {
      const confirmed = await confirm({
        title: 'Unsaved changes',
        message: `Do you want to leave and cancel your changes?`,
        cancelText: 'Leave',
        confirmText: 'Stay',
        confirmColor: 'primary',
        hideCloseIcon: false,
        onClose: () => {},
        URL: '/settings/brands',
      });
      confirmed && setOpen(false);
    } else {
      history.push('/settings/brands');
    }
  };
  useEffect(() => {
    Object.keys(addressInfo).forEach((item) => {
      brandFormMethods.setValue(item, addressInfo[item]);
    });
  }, [addressInfo]);

  const isValidGoogleValue = useCallback((isValid) => {
    return setNextDisabled(isValid);
  }, []);

  //newFunctions For accepting
  const handleAcceptAgreeToReceiveOrderCommunication = ({ target }) => {
    setAcceptAgreeToReceiveOrderCommunication(target.checked);
    !target.checked
      ? setAcceptAgreeToReceiveOrderCommunicationError(true)
      : setAcceptAgreeToReceiveOrderCommunicationError(false);
  };

  const handleAcceptAgreeToPayRecurringCharges = ({ target }) => {
    setAgreeToPayRecurringCharges(target.checked);
    !target.checked ? setAgreeToPayRecurringChargesError(true) : setAgreeToPayRecurringChargesError(false);
  };

  const handleAcceptSetupFree = ({ target }) => {
    setAgreeToPaySetupFee(target.checked);
    !target.checked ? setAgreeToPaySetupFeeError(true) : setAgreeToPaySetupFeeError(false);
  };
  console.log(selectedBrandName, 'selectedBrandName');
  return (
    <>
      <DialogAddSelectItem
        open={open}
        modalBrandFormMethods={modalBrandFormMethods}
        handleClickSave={handleClickSave}
        handleClose={handleClose}
      />
      <FormProvider {...brandFormMethods}>
        <form onSubmit={brandFormMethods.handleSubmit(handleFormSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ height: '72px' }}>
              <CustomTextInput
                sx={{ width: '100%' }}
                name="locationName"
                label={<LabelRequired>{t('add_location.location_name')}</LabelRequired>}
              />
            </Grid>
            <Grid item xs={12} sx={{ height: '72px' }}>
              <CustomSelectAddItem
                name="brandName"
                label={<LabelRequired>{t('sign_up.brands_list')}</LabelRequired>}
                control={control}
                errors={isSubmitted ? errors : null}
                handleAddItem={handleAddItem}
                options={data}
                setValue={brandFormMethods.setValue}
                setSelectedBrandName={setSelectedBrandName}
                setCurrentBrandId={setCurrentBrandId}
                setHasValueForCreation={setHasValueForCreation}
                sx={{
                  width: '100%',
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ height: '72px' }}>
              <GooglAutocomplete
                getAddressFields={getAddressFields}
                name="address1"
                label={<LabelRequired>{t('order_view.address')}</LabelRequired>}
                initialValue={addressInfo.address1}
                formSubmitted={isSubmitting}
                placeholder=""
                isValidGoogleValue={isValidGoogleValue}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sx={{ height: '72px' }}>
              <CustomTextInput sx={{ width: '100%' }} name="address2" label={t('order_view.address2')} />
            </Grid>
            <Grid item xs={4} sx={{ height: '72px' }}>
              <CustomTextInput
                sx={{ width: '100%' }}
                name="city"
                label={<LabelRequired>{t('settings.city')}</LabelRequired>}
              />
            </Grid>
            <Grid item xs={4} sx={{ height: '72px' }}>
              <CustomTextInput
                sx={{ width: '100%' }}
                name="state"
                label={<LabelRequired>{t('settings.state')}</LabelRequired>}
              />
            </Grid>
            <Grid item xs={4} sx={{ height: '72px' }}>
              <CustomTextInput
                sx={{ width: '100%' }}
                defaultValue=""
                name="zipCode"
                label={<LabelRequired>{t('settings.zip_code')}</LabelRequired>}
              />
            </Grid>
            <Grid item md={12} xs={6}>
              <Typography sx={{ fontWeight: 700, mt: 2 }}>{t('settings.communication_information_reports')}</Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <CustomTextInput
                sx={{ width: '100%' }}
                name="metadata.communicationEmail"
                label={<LabelRequired>{t('sign_in.communication_email')}</LabelRequired>}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomCheckbox
                sx={{ mb: 2 }}
                label={t('add_location.copy_new_location')}
                name="cloneMenu"
                disabled={false}
              />
              {cloneMenu &&
                <BusinessSelect
                  businessList={businessList}
                  brand={selectedBrandName}
                  setIds={setIds}
                />
              }
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ mt: 2, mb: 1, fontSize: 20, fontWeight: 'bold' }}>
                {t('sign_up.choose_subscription_plan')}
              </Typography>
              <Box sx={{ minHeight: 400 }}>
                <SubscriptionPlans
                  required={true}
                  isSubmitted={isSubmitted}
                  getSelectedPlanPrice={getSelectedPlanPrice}
                  handleSelectedPlan={handleSelectedPlan}
                  selectedPlanPrice={selectedPlanPrice}
                  selectedPlan={selectedPlan}
                  target={isSpecialLocation ? 'location' : 'signup'}
                  setSelectedPlanData={setSelectedPlanData}
                  setCheckNewPlans={setCheckNewPlans}
                  isSpecialLocation={isSpecialLocation}
                />
              </Box>
            </Grid>

            {selectedPlan && selectedPlan !== 'null' && checkNewPlans ? (
              <Grid item xs={12}>
                <ApplyCoupon
                  couponValue={couponValue}
                  setCouponValue={setCouponValue}
                  setIsApplay={setIsApplay}
                  handleAcceptSetupFree={handleAcceptSetupFree}
                  acceptAgreeToPaySetupFeeCharges={agreeToPaySetupFee}
                  acceptAgreeToPaySetupFee={agreeToPaySetupFeeError}
                  checkNewPlans={checkNewPlans}
                />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12}>
              <AcceptRequirements
                handleAcceptAgreeToReceiveOrderCommunication={handleAcceptAgreeToReceiveOrderCommunication}
                acceptAgreeToReceiveOrderCommunication={acceptAgreeToReceiveOrderCommunication}
                acceptAgreeToReceiveOrderCommunicationError={acceptAgreeToReceiveOrderCommunicationError}
                selectedPlan={selectedPlan}
                handleAcceptAgreeToPayRecurringCharges={handleAcceptAgreeToPayRecurringCharges}
                agreeToPayRecurringCharges={agreeToPayRecurringCharges}
                agreeToPayRecurringChargesError={agreeToPayRecurringChargesError}
                selectedPlanData={selectedPlanData}
                selectedPlanPrice={selectedPlanPrice}
                checkNewPlans={checkNewPlans}
                isSpecialLocation={isSpecialLocation}
              />
            </Grid>
            <Grid justifyContent="flex-end" display="flex" item xs={12}>
              <BackButton
                id="addLocationBack"
                variant="contained"
                color="secondary"
                size="medium"
                onClick={handleClickBack}
              >
                {t('sign_up.back')}
              </BackButton>
              {!setUpFeeEnabled ? (
                <Button
                  id="addLocationView"
                  disabled={
                    !getValues()?.metadata?.communicationEmail ||
                    !getValues()?.zipCode || 
                    !getValues()?.city ||
                    !getValues()?.state ||
                    loading ||
                    !nextDisabled ||
                    !selectedBrandName ||
                    !selectedPlan ||
                    !isValid ||
                    (checkNewPlans && (!acceptAgreeToReceiveOrderCommunication || !agreeToPayRecurringCharges)) ||
                    !acceptAgreeToReceiveOrderCommunication
                  }
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {loading && <CircularProgress style={{ width: '25px', height: '25px' }} />}
                  {!loading && t('order_view.submit')}
                </Button>
              ) : (
                <Button
                  id="addLocationView"
                  disabled={
                    !getValues()?.metadata?.communicationEmail ||
                    !getValues()?.zipCode || 
                    !getValues()?.city ||
                    !getValues()?.state ||
                    loading ||
                    !nextDisabled ||
                    !selectedBrandName ||
                    !selectedPlan ||
                    !isValid ||
                    (checkNewPlans &&
                      (!acceptAgreeToReceiveOrderCommunication ||
                        !agreeToPayRecurringCharges ||
                        !agreeToPaySetupFee)) ||
                    !acceptAgreeToReceiveOrderCommunication
                  }
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {loading && <CircularProgress style={{ width: '25px', height: '25px' }} />}
                  {!loading && t('order_view.submit')}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default AddLocationFirstStep;
