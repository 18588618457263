import { Button, Box } from '@mui/material';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import EmptyMenu from 'components/MenuNew/EmptyMenu';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { MODE, MUI_TO_SX_STYLES } from 'pages/TableOrdering/config';
import TableOrderModal from 'pages/TableOrdering/Tables/TableOrderModal';
import { HeaderContainer, StripedDataGrid } from 'pages/TableOrdering/styles';
import CreateOrEditForm from 'pages/TableOrdering/Employees/CreateOrEditForm';
import { useTableOrderingEmployee } from 'pages/TableOrdering/hooks/useTableOrderingEmployee';

const Employees = () => {
  const { t } = useTranslation();
  const {
    open,
    mode,
    handleCancel,
    formMethods,
    handleSubmit,
    employeeFilter,
    employeeColumns,
    rowsPerPage,
    setRowsPerPage,
    lastCreatedEmployee,
    handleOpen,
    loading,
    visiblePinCode,
    showPinCode,
    jobs,
    fields,
    handleAddNewJob,
    handleRemove,
    handleChange,
    handleChangePay,
    currentJobsIds,
    isDisabled,
  } = useTableOrderingEmployee();

  return (
    <Box>
      <HeaderContainer>
        <Button
          id="tablesAddTable"
          onClick={handleOpen}
          variant="contained"
          color="primary"
          type="button"
          className={`${open ? 'disabledButton' : ''}`}
          startIcon={<AddOutlinedIcon />}
        >
          {t('Add New Employee')}
        </Button>
      </HeaderContainer>
      <TableOrderModal
        open={open}
        handleClose={handleCancel}
        title={mode === MODE.CREATE ? t('Add New Employee') : t('Edit Employee')}
        disabled={
          mode === MODE.CREATE ? (
            isDisabled ||
            Object.keys(formMethods.formState.errors).length > 0
          ) : (
            isDisabled ||
            Object.keys(formMethods.formState.errors).length > 0
          )
        }
        saveTitle={mode === MODE.CREATE ? t('Add New Employee') : t('Save')}
        cancelTitle={'Cancel'}
        formMethods={formMethods}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <CreateOrEditForm
          visiblePinCode={visiblePinCode}
          showPinCode={showPinCode}
          jobs={jobs}
          formMethods={formMethods}
          fields={fields}
          handleAddNewJob={handleAddNewJob}
          handleRemove={handleRemove}
          handleChange={handleChange}
          handleChangePay={handleChangePay}
          currentJobsIds={currentJobsIds}
        />
      </TableOrderModal>
      <StripedDataGrid
        sx={MUI_TO_SX_STYLES}
        autoHeight={true}
        hideFooterSelectedRowCount={true}
        isEditable={false}
        hideFooter={true}
        rowsPerPageOptions={[200]}
        getRowClassName={(params) => (params?.row?.id === lastCreatedEmployee ? 'highlight_last-selected_item' : '')}
        rows={employeeFilter}
        columns={employeeColumns}
        pageSize={rowsPerPage}
        onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
        components={{
          LoadingOverlay: Preloader,
          NoRowsOverlay: () => (
            <Box sx={{ mt: '100px' }}>
              <EmptyMenu text={t('There are no employees yet')} />
            </Box>
          ),
        }}
        loading={loading}
      />
    </Box>
  );
};
export default Employees;
